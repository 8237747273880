import React from "react";

import { ProjectCard } from "components/ProjectCard";

import s from "./index.module.scss";

export const ProjectFooter = ({ projects, currentProject }) => {
  return (
    <>
      <div className={s.footer}>
        <div className={s.content}>
          <p className={s.catchphrase}>Like this project? Then you're going to <strong>love</strong>...</p>
          <div className={s.projectsContainer}>
            {projects.map((project, i) => (
              <ProjectCard project={project} key={i} />
            ))}
          </div>
          <p className={s.catchphrase2}>...or shoot me a message at <a href="mailto:jono@jono.is?subject=I'd%20like%20to%20work%20with%20you" style={{ color: currentProject.projectColor }}>jono@jono.is</a> to make something even better!</p>
        </div>
      </div>
      <div className={s.edge} id="edge" />
    </>
  );
};
