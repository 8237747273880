import React, { useState, useEffect, useMemo, useRef } from "react";
import gsap, { drawSVG } from "gsap";

import s from "./index.module.scss";

export function SunMoonIcon({ isDark }) {
  const svgContainer = useRef(null);
  const centerCircle = useRef(null);
  const maskedCircle = useRef(null);
  const lines = useRef(null);

  useEffect(() => {
    const properties = {
      dark: {
        r: 9,
        transform: "rotate(40deg)",
        cx: 12,
        cy: 4,
        opacity: 0,
      },
      light: {
        r: 5,
        transform: "rotate(90deg)",
        cx: 30,
        cy: 0,
        opacity: 1,
      }
    };
    const { r, transform, cx, cy, opacity } =
      properties[isDark ? "dark" : "light"];

    svgContainer && gsap.to(svgContainer.current, { transform: transform });
    centerCircle && gsap.to(centerCircle.current, { r: r });
    maskedCircle && gsap.to(maskedCircle.current, { cx: cx, cy: cy });
    lines && gsap.to(lines.current, { opacity: opacity });
  }, [centerCircle, isDark, lines, maskedCircle, svgContainer]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
      ref={svgContainer}
      style={{
        cursor: "pointer",
      }}
    >
      <mask id="myMask2">
        <rect x="0" y="0" width="100%" height="100%" fill="white" />
        <circle ref={maskedCircle} r="9" fill="black" />
      </mask>

      <circle
        cx="12"
        cy="12"
        ref={centerCircle}
        fill={isDark ? "white" : "black"}
        mask="url(#myMask2)"
      />
      <g stroke={isDark ? "white" : "black"} ref={lines}>
        <line x1="12" y1="1" x2="12" y2="3" />
        <line x1="12" y1="21" x2="12" y2="23" />
        <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
        <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
        <line x1="1" y1="12" x2="3" y2="12" />
        <line x1="21" y1="12" x2="23" y2="12" />
        <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
        <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
      </g>
    </svg>
  );
}
