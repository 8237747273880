import { useEffect } from "react";
import gsap from "gsap";

export const MouseFollow = () => {
  useEffect(() => {
    // Mousemove handler
    const handleMouseMove = (e) => {
      let xTo = gsap.quickTo(".ball", "x", { duration: 0.01, ease: "power3" }),
        yTo = gsap.quickTo(".ball", "y", { duration: 0.01, ease: "power3" });
      xTo(e.pageX - 25);
      yTo(e.pageY - 25);
    };
    document.addEventListener("mousemove", handleMouseMove);
    gsap.to(".ball", { rotation: "360", duration: 8, ease: 'none', repeat: -1 });
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <svg
      id="rotatingText"
      className="ball"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        borderRadius: "50%",
        pointerEvents: "none",
      }}
      viewBox="0 0 200 200"
      width="200"
      height="200"
    >
      <defs>
        <path
          id="circle"
          d="M 100, 100
            m -75, 0
            a 75, 75 0 1, 0 150, 0
            a 75, 75 0 1, 0 -150, 0
            "
        ></path>
      </defs>
      <text width="400" style={{
        fontWeight: 600,
        letterSpacing: 1.7,
        textTransform: "uppercase",
        fontFamily: 'Kanit',
        fontSize: 24,
        fill: "#111",
        textShadow: "2px 0 #ccc",
      }}>
        <textPath alignment-baseline="top" href="#circle" class="text">
          {" "}
          Welcome home, friend!
        </textPath>
      </text>
    </svg>
  );
};
