import React from "react";

// Style
import s from "./index.module.scss";

export const FullWidthImage = ({ node }) => {
  const { image } = node;

  return (
    <div className={s.fullWidthImage}>
      <img alt="" className={s["image"]} src={image} />
    </div>
  );
};
