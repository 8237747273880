import React from "react";

// Style
import s from "./index.module.scss";

export const InlineImage = ({ node }) => {
  const { image, backgroundColor, roundedCorners, shadow } = node;

  return (
    <img
      alt=""
      className={s.inlineImage}
      src={image}
      style={{
        backgroundColor: backgroundColor,
        borderRadius: roundedCorners ? "20px" : 0,
        boxShadow: shadow ? "0 0 30px grey" : "none",
      }}
    />
  );
};
