import React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components"

import { IntroDescription } from "./IntroDescription";

import { gradientStyleToRight, gradientStyleToBottom } from "utils/style-utils";

// Style
import s from "./index.module.scss";

export const Intro = ({
  title,
  clientName,
  description,
  headline,
  hats,
  link,
  gradientValues,
  projectColor,
  ...props
}) => {
  const gradient =
    gradientValues?.length >= 2 ? gradientValues : ["black", "white"];

  const ClientBody = projectColor
    ? styled.div`
        a {
          color: ${projectColor};
          &:hover {
            color: ${gradientValues[0]};
          }
        }
      `
    : styled.div``;

  return (
    <div className={s.introContainer}>
      <div
        className={s.projectGradientTop}
        style={{ backgroundImage: gradientStyleToRight(gradient) }}
      />
      <div className={s.intro}>
        <div className={s.clientName}>
          <h2 className={s.eyebrow} style={{ color: projectColor }}>
            <span>{title} for </span><a href={link}>{clientName}</a>
          </h2>

          <div className={s.title}>
            <div
              className={s.projectGradientSide}
              style={{ backgroundImage: gradientStyleToBottom(gradient) }}
            ></div>
            <h1>{headline}</h1>
          </div>
        </div>

        {/* AREA 2: VISIT */}

        <div className={s.visit}>
          <Link to={link} className="button">
            visit site
          </Link>
        </div>

        {/* AREA 3: PARAGRAPH */}
        <ClientBody className={s.clientBody}>
          <IntroDescription blocks={description} />
        </ClientBody>

        {/* AREA 4: HATS*/}
        <div className={s.hats}>
          <h4>
            Hats worn:
          </h4>
          {hats &&
            hats.map((hat, i) => (
              <React.Fragment key={i}>
                <Link
                  className={s.hat}
                  style={{ backgroundColor: projectColor }}
                  to={`/off-the-grid/#${hat.slug}`}
                >
                  {hat.hat}
                </Link>
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};
