import React, { useEffect, useContext, useState } from "react";
import gsap from "gsap";
import { getData } from "utils/sanity";
import { homepageQuery } from "utils/sanity/sanity-queries";

// Components
import { UserButton } from "components/UserButton";

// Contexts and Utils
import { UserContext } from "contexts/UserContext";
import { useEdgeManager } from "contexts/EdgeManager";
import { AnimatedText } from "utils/AnimatedText";

// Style
import s from "./index.module.scss";

export const Home = ({ loaderDone }) => {
  var tl = gsap.timeline({ paused: false, id: "timeline" });
  const { users, usersLoaded } = useContext(UserContext);
  const { setEdge, bgColor } = useEdgeManager();
  const [greeting, setGreeting] = useState("Loading");

  useEffect(() => {
    async function fetchData() {
      try {
        const homeData = await getData(homepageQuery);
        setGreeting(homeData.greeting);
      } catch (err) {
        console.log("fetch failed", err);
        throw err;
      }
    }
    fetchData();
  }, []);

  // useEffect(() => {
  //   tl.to(".button", {
  //     opacity: 1,
  //     stagger: 0.4,
  //     duration: 1,
  //   });

  //   if (!scrollDisabled && usersLoaded) {
  //     tl.play();
  //   }
  // }, [scrollDisabled, tl, usersLoaded]);

  useEffect(() => {
    loaderDone && gsap.to("#edge", { backgroundColor: bgColor });
    document.title = "Jono is a product designer"
    setEdge(true);
  }, [bgColor, loaderDone, setEdge]);

  return (
    <section className={s.homeHero}>
      {greeting && <AnimatedText>Jono is a product designer. Who are you?</AnimatedText>}
      <div className={s.helloButtons}>
        {users.map((user, i) => (
          <UserButton user={user} key={i} />
        ))}
      </div>
    </section>
  );
};
